.question-navigator-list {
  list-style: none;
  margin-bottom: 20px;
}

.question-navigator-item {
  display: inline-block;
  border: 1px solid green;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 8px 20px 0 20px;
}

.question-navigator-item button {
  font-size: 1.1rem;
  border-radius: 50%;
  background-color: transparent;
  border: none;
  color: purple;
  width: 100%;
  height: 100%;
}

.question-navigator-item button:hover, .question-navigator-item button:focus {
  cursor: pointer;
  background-color: rgb(200, 255, 200);
}

.question-navigator-item.current {
  background-color: rgb(230, 255, 200);
}