.join-landing {
  margin-top: 40px;
}

.join-landing form {
  background-color: rgba(50, 120, 130, 0.85);
  padding: 20px;
  display: inline-block;
}

.join-landing label {
  display: block;
  font-size: 3rem;
  color: white;
}

.join-landing input {
  height: 50px;
  padding: 8px;
  width: 80%;
  max-width: 300px;
  display: block;
  margin: 20px auto;
  font-size: 2rem;
  text-align: center;
  border: 2px solid rgb(234, 150, 146);
}

.join-landing button {
  font-size: 2rem;
  padding: 8px;
  border-radius: 5px;
  background-color: rgb(80, 164, 176);
  color: white;
  -webkit-transition: background-color 400ms linear;
  -ms-transition: background-color 400ms linear;
  transition: background-color 400ms linear;
}

.join-landing button:hover, .join-landing button:focus {
  cursor: pointer;
  color: rgb(80, 164, 176);
  background-color: white;
  -webkit-transition: background-color 500ms linear;
  -ms-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
}
