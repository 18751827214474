.live-game-scoreboard {
  color: white;
  max-width: 1100px;
  margin: 30px auto 0 auto;
}

.live-game-scoreboard .team-score-list {
  list-style: none;
  border-top: 1px solid gray;
}

.live-game-scoreboard h2 {
  display: inline-block;
  font-size: 2rem;
}

.live-game-scoreboard button {
  border: none;
  background: none;
  text-decoration: underline;
  color: rgb(220, 200, 255);
  margin-left: 20px;
}

.live-game-scoreboard button:hover, .live-game-scoreboard button:focus {
  cursor: pointer;
  color: rgb(255, 220, 220);
}

@media only screen and (min-width: 500px) {
  .live-game-scoreboard h2 {
    font-size: 3rem;
  }
}