.create-new-button {

}

.create-new-button button {
  border-radius: 50px;
  border: none;
  background-color: rgb(233, 233, 255);
  color: rgb(40, 150, 100);
  font-size: 5rem;
}

.create-new-button i:hover, .create-new-button p:hover {
  cursor: pointer;
}

.create-new-button p {
  color: white;
  background-color: rgb(40, 150, 100);
  display: inline;
  font-size: 1.5rem;
  border-radius: 5%;
  padding: 10px 10px 10px 5px;
  position: relative;
  bottom: 23px;
  right: 14px;
}

@media only screen and (min-width: 1000px) {
  .create-new-button {
    text-align: right;
  }
}