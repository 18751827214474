.team-score-item {
  text-align: left;
  margin-bottom: 20px;
}

.team-score-item .team-place-number {
  font-size: 3.5rem;
  float: left;
  margin-right: 20px;
  width: 20px;
}

.team-score-item h3 {
  font-size: 1.5rem;
  display: inline-block;
  width: calc(100% - 45px);
  height: 2rem;
  overflow: hidden;
  text-overflow: clip;
}

.team-score-item .team-points {
  display: inline-block;
}

@media only screen and (min-width: 500px) {
  .team-score-item h3 {
    font-size: 2rem;
    height: 2.7rem;
  }
}

@media only screen and (min-width: 600px) {
  .team-score-item .team-points,
  .team-score-item .team-questions-answered {
    display: inline-block;
  }

  .team-score-item h3 {
    width: calc(100% - 230px);
    padding-right: 40px;
  }

  .team-score-item .team-points {
    font-size: 2rem;
  }

  .team-score-item .team-questions-answered {
    position: relative;
    bottom: 10px;
  }
}

@media only screen and (min-width: 800px) {
  .team-score-item h3,
  .team-score-item .team-points,
  .team-score-item .team-questions-answered {
    display: inline-block;
  }

  .team-score-item h3 {
    width: calc(100% - 320px);
    font-size: 3rem;
    height: 4rem;
    padding-right: 50px;
  }

  .team-score-item .team-points {
    font-size: 3rem;
  }
}