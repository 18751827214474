html {
  font-size: 16px;
}

body {
  min-height: 100vh;
  min-width: 100vw;
  background-color: rgb(50, 120, 130);
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  margin: 0;
}

body * {
  font-family: 'Quicksand', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

.error-message {
  color: rgb(255, 200, 200);
  margin: 10px 0;
}

.error-input {
  background-color: rgb(255, 230, 230);
}


.index-landing-page .loading-spinner {
  text-align: center;
}

.index-landing-page {
  text-align: center;
}

.index-landing-page #sparkles-container {
  position: absolute;
  top: 175px;
  left: 0;
  z-index: -1;
  width: calc(100% + 60px);
  height: calc(100vh - 175px);
  margin: -30px 0 0 -40px;
}

.index-landing-page canvas {
  transition: visibility 0ms, opacity 1500ms linear;
  -webkit-transition: visibility 0ms, opacity 1500ms linear;
  -ms-transition: visibility 0ms, opacity 1500ms linear;
}
.landing-page-header h1 {
  font-family: 'Indie Flower', cursive;
  font-size: 1.2rem;
}

.landing-page-header h1 span:nth-child(1) {
  background-color: rgb(234, 150, 146);
  color: rgb(50, 120, 130);
  font-style: italic;
  padding: 8px 5px 8px 8px;
  text-decoration: underline;
  border: 1px solid rgb(234, 150, 146);
}

.landing-page-header h1 span:nth-child(2) {
  background-color: rgb(50, 120, 130);
  color: rgb(234, 150, 146);
  font-style: italic;
  padding: 8px 8px 8px 0;
  border: 1px solid rgb(234, 150, 146);
  text-decoration: underline;
}


.landing-page-header ul {
  width: 100vw;
  margin: 15px 0 0 -20px;
  background-color: rgb(40, 110, 120);
}


.landing-page-header li {
  display: inline-block;
  width: 33%;
}

.landing-page-header li a {
  display: inline-block;
  padding: 8px;
  width: 100%;
  color: white;
  border-bottom: 1px solid rgb(40, 110, 120);
  -webkit-transition: border-bottom 250ms linear, background-color 250ms linear;
  transition: border-bottom 250ms linear, background-color 250ms linear;
}

.landing-page-header .selected-route {
  color: rgb(234, 150, 146);
  text-decoration: overline;
  font-weight: bold;
}

.landing-page-header li a:hover, .landing-page-header li a:focus {
  cursor: pointer;
  background-color: rgb(50, 120, 130);
  border-bottom: 1px solid white;
  -webkit-transition: border-bottom 250ms linear, background-color 250ms linear;
  transition: border-bottom 250ms linear, background-color 250ms linear;
}

/* ----- Styling for slide-up and slide-down of header nav-list ----- */
.landing-page-header .nav-list li.slideup, 
.landing-page-header .nav-list li,
.landing-page-header .nav-list.slideup,
.landing-page-header .nav-list {
  max-height: 0px;
  overflow-y: hidden;
  -webkit-transition: max-height 1.5s ease-in-out;
  transition: max-height 1.5s ease-in-out;
}
.landing-page-header .nav-list li,
.landing-page-header .nav-list {
  overflow-y: visible;
  overflow-y: initial;
  max-height: 100px;
}



@media only screen and (min-width: 600px) {
  .landing-page-header h1 {
    font-size: 2rem;
  }
  .landing-page-header ul {
    text-align: right;
  }
  .landing-page-header li {
    width: auto;
    width: initial;
  }
}

@media only screen and (min-width: 1000px) {
  .landing-page-header ul {
    padding-right: calc((100vw - 1000px) / 2);
  }
}
.about-main {
  text-align: left;
  color: white;
  max-width: calc(100vw - 100px);
  margin: 20px auto 0 auto;
}

.about-main h2 {
  font-size: 3rem;
  text-shadow: 1px 1px 1px black;
  margin-bottom: 20px;
}

.about-main ol, .about-main ul {
  list-style: none;
}

.about-main .about-step {
  margin-bottom: 40px;
  padding: 10px;
  background-color: rgb(40, 110, 120);
}

.about-main .about-step h3 {
  text-shadow: 1px 1px 1px black;
  font-size: 1.6rem;
  margin-bottom: 20px;
}

.about-main .screenshot-container {
  text-align: center;
}

.about-main .screenshot {
  width: 100%;
  margin: 20px 0 20px 0;
  max-width: 700px;
  box-shadow: 2px 2px 2px rgb(200, 200, 200);
  border: 1px solid rgb(200, 200, 200);
}

.about-main .screenshot.bottom {
  margin-bottom: 0;
}

@media only screen and (min-width: 600px) {
  .about-main .about-step ul {
    padding-left: 20px;
  }
}

@media only screen and (min-width: 1100px) {
  .about-main .substep {
    display: inline-block;
    width: calc(49% - 5px);
    vertical-align: top;
  }
  .about-main .substep.one {
    padding-right: 30px;
    border-right: 1px solid rgb(100, 150, 150);
  }  
  .about-main .substep.two {
    padding-left: 30px;
  }
}
.teacher-main {
  margin-top: 30px;
  text-align: center;
  color: white;
}

.teacher-main h2 {
  margin-bottom: 30px;
}

.teacher-main label {
  margin-bottom: 10px;
  display: block;
  text-align: center;
}

.teacher-main input {
  margin-bottom: 20px;
  text-align: center;
  width: 250px;
  padding: 5px;
  font-size: 1.2rem;
}

.teacher-main button {
  display: block;
  margin: 0 auto 20px auto;
  text-align: center;
}

.teacher-main .toggle-login-register button {
  display: inline;
  display: initial;
  margin: 0 0 0 8px;
}

.teacher-main .loading-spinner {
  margin: 20px auto 0 auto;
  width: 5px;
  height: 50px;
}

.teacher-main button[type='submit'] {
  color: rgb(30, 100, 110);
  font-size: 1.1rem;
  margin-bottom: 40px;
  padding: 5px;
  border-radius: 5px;
  -webkit-transition: background-color 250ms linear, color 250ms linear;
  transition: background-color 250ms linear, color 250ms linear;
}

.teacher-main button[type='submit']:hover, .teacher-main button[type='submit']:focus {
  cursor: pointer;
  color: white;
  background-color: rgb(40, 110, 120);
  -webkit-transition: background-color 250ms linear, color 250ms linear;
  transition: background-color 250ms linear, color 250ms linear;
}

.teacher-main button.demo-account-button, 
.teacher-main button.toggle-login-register-button {
  background: none;
  border: none;
  text-decoration: underline;
  color: rgb(230, 230, 230);
}

.teacher-main button.demo-account-button:hover, 
.teacher-main button.demo-account-button:focus, 
.teacher-main button.toggle-login-register-button:hover,
.teacher-main button.toggle-login-register-button:focus {
  cursor: pointer;
  color: white;
}




/* ------- Animation for Loading Paragraph ----------- */
.teacher-main .spinner-text {
  color: white;
  margin-top: 20px;

  -webkit-animation: fadein 1.5s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
          animation: fadein 1.5s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */

/* Opera < 12.1 */
.join-landing {
  margin-top: 40px;
}

.join-landing form {
  background-color: rgba(50, 120, 130, 0.85);
  padding: 20px;
  display: inline-block;
}

.join-landing label {
  display: block;
  font-size: 3rem;
  color: white;
}

.join-landing input {
  height: 50px;
  padding: 8px;
  width: 80%;
  max-width: 300px;
  display: block;
  margin: 20px auto;
  font-size: 2rem;
  text-align: center;
  border: 2px solid rgb(234, 150, 146);
}

.join-landing button {
  font-size: 2rem;
  padding: 8px;
  border-radius: 5px;
  background-color: rgb(80, 164, 176);
  color: white;
  -webkit-transition: background-color 400ms linear;
  transition: background-color 400ms linear;
}

.join-landing button:hover, .join-landing button:focus {
  cursor: pointer;
  color: rgb(80, 164, 176);
  background-color: white;
  -webkit-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
}


.enter-username {
  color: white;
  background-color: rgba(50, 120, 130, 0.85);
  display: inline-block;
  padding: 0 20px 20px 20px;
  margin-top: 30px;
}


.enter-username label {
  display: block;
  font-size: 3rem;
  color: white;
}

.enter-username input {
  height: 50px;
  padding: 8px;
  width: 80%;
  max-width: 300px;
  display: block;
  margin: 20px auto;
  font-size: 2rem;
  text-align: center;
  border: 2px solid rgb(234, 150, 146);
}

.enter-username button {
  font-size: 2rem;
  padding: 8px;
  border-radius: 5px;
  background-color: rgb(80, 164, 176);
  color: white;
  -webkit-transition: background-color 400ms linear;
  transition: background-color 400ms linear;
}

.enter-username button:hover, .enter-username button:focus {
  cursor: pointer;
  color: rgb(80, 164, 176);
  background-color: white;
  -webkit-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
}

.enter-username .error-message {
  color: rgb(255, 200, 200);
}

.player-joined-and-waiting {
  color: white;
}

.player-joined-and-waiting h2 {
  font-size: 3rem;
  margin: 100px 0 20px 0;
}

.player-joined-and-waiting p {
  font-style: italic;
}
.live-game-ready-screen {
  color: white;
}

.live-game-ready-screen ol {
  text-align: left;
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
}

.live-game-ready-screen p {
  margin: 20px 0;
  text-align: left;
}

.live-game-ready-screen .ready-message {
  font-size: 3rem;
  margin: 50px 0 0 0;
  text-align: center;
}
.live-game-playing-main {  
  max-width: 1100px;
  margin: 0 auto;
  padding-bottom: 20px;
}
.header-submission-list {
  color: white;
  text-align: left;
  margin-bottom: 60px;
}

.header-submission-list ul {
  list-style: circle inside;
}

.header-submission-list li {
  margin: 10px 0;
}

.header-submission-list li.already-guessed {
  color: rgb(230, 230, 230);
  font-style: italic;
}

.header-submission-list li.correct {
  color: rgb(200, 255, 200);
}

.header-submission-list li.wrong {
  color: rgb(255, 200, 200);
  text-decoration: line-through;
}

.header-submission-list .skip-button {
  background: transparent;
  border: none;
  margin-left: 8px;
  color: rgb(255, 220, 200);
  text-decoration: underline;
}

.header-submission-list .skip-button:hover, .header-submission-list .skip-button:focus {
  cursor: pointer;
}
.display-question {
  color: white;
}

.display-question .display-equation {
  font-size: 3rem;
  margin-bottom: 40px;
}

.display-question .instructions {
  margin: 10px 0;
}

.display-question label {
  margin-top: 20px;
  display: block;
}

.display-question input {
  display: block;
  margin: 10px auto 20px auto;
  font-size: 1.5rem;
  width: 80%;
  max-width: 300px;
  padding: 8px;
  text-align: center;
}

.display-question span {
  font-style: italic;
  font-size: 1.4rem;
}

.display-question button {
  font-size: 2rem;
  padding: 8px;
  border-radius: 5px;
  background-color: rgb(80, 164, 176);
  color: white;
  -webkit-transition: background-color 400ms linear;
  transition: background-color 400ms linear;
}

.display-question button:hover, .display-question button:focus {
  cursor: pointer;
  color: rgb(80, 164, 176);
  background-color: white;
  -webkit-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
}

.display-question .feedback.correct {
  font-style: italic;
  color: rgb(200, 255, 200);
}
.display-question .feedback.wrong {
  font-style: italic;
  color: rgb(255, 200, 200);
}
.guessing-for-points-after .sk-fade-in.sk-spinner.sk-circle {
  margin: 0 auto;
}

.guessing-for-points-after h3 {
  font-size: 3rem;
  margin: 80px 0;
}

.guessing-for-points-after p {
  font-style: italic;
  color: white;
}

.guessing-for-points-after.positive {
  color: rgb(200, 255, 200);
}

.guessing-for-points-after.negative {
  color: rgb(255, 200, 200);
}


.guessing-for-points-after button {
  padding: 8px;
  font-size: 1.5rem;
  border-radius: 5px;
}

.guessing-for-points-after button {
  font-size: 2rem;
  padding: 8px;
  border-radius: 5px;
  background-color: rgb(80, 164, 176);
  color: white;
  -webkit-transition: background-color 400ms linear;
  transition: background-color 400ms linear;
}

.guessing-for-points-after button:hover, .guessing-for-points-after button:focus {
  cursor: pointer;
  color: rgb(80, 164, 176);
  background-color: white;
  -webkit-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
}
.guessing-for-points-before {
  color: white;
}

.guessing-for-points-before ul {
  text-align: left;
  list-style: none;
  margin: 0 auto 30px auto;
  max-width: 1000px;
}

.guessing-for-points-before li.correct {
  color: rgb(200, 255, 200);
}

.guessing-for-points-before li.incorrect {
  color: rgb(255, 200, 200);
}

.guessing-for-points-before p {
  margin: 20px 0;
}

.guessing-for-points-before .answer {
  font-size: 1.5rem;
}

.guessing-for-points-before button {
  margin-top: 30px;
  font-size: 2rem;
  padding: 8px;
  border-radius: 5px;
  background-color: rgb(80, 194, 176);
  color: white;
  -webkit-transition: background-color 400ms linear;
  transition: background-color 400ms linear;
}

.guessing-for-points-before button:hover, .guessing-for-points-before button:focus {
  cursor: pointer;
  color: rgb(80, 194, 176);
  background-color: white;
  -webkit-transition: background-color 500ms linear;
  transition: background-color 500ms linear;
}
.guessing-for-points-guess {
  max-width: 1000px;
  margin: 30px auto 0 auto;
}

.guessing-for-points-guess h2 {
  color: white;
  margin-bottom: 13vh;
}


.guessing-for-points-guess .treasure-box {
  max-width: 100px;
}

.guessing-for-points-guess .treasure-box:hover {
  max-width: 110px;
  position: absolute;
  bottom: -40px;
  left: calc(50% - 55px);
}
.guessing-for-points-guess .treasure-box.three:hover {
  position: relative;
  left: 0;
  top: 0;
}

.guessing-for-points-guess .treasure-box-container {
  display: inline-block;
  width: 49%;
  max-width: 100%;
  margin-bottom: 40px;
}

.guessing-for-points-guess .treasure-box-container:hover {
  position: relative;
}


@media only screen and (min-width: 640px) {
  .guessing-for-points-guess h2 {
    margin-bottom: 6vh;
  }

  .guessing-for-points-guess .treasure-box {
    max-width: 200px;
  }
  
  .guessing-for-points-guess .treasure-box:hover {
    max-width: 220px;
    left: calc(50% - 110px);
  }
  
  .guessing-for-points-guess .treasure-box-container {
    display: inline-block;
  }

  .guessing-for-points-guess {
    margin: 60px auto;
  }  
}
.guessing-for-points-watch {
  color: white;
}

.guessing-for-points-watch h2 {
  margin: 60px 0;
}
.team-finished-screen {
  color: white;
  margin-top: 80px;
}
.player-final-results-screen {
  color: white;
  margin-top: 60px;
}

.player-final-results-screen .team-score {
  font-size: 2.5rem;
}

.player-final-results-screen .team-place {
  font-size: 2.5rem;
}

.player-final-results-screen.first {
  color: rgb(255, 215, 0);
}

.player-final-results-screen.second {
  color: rgb(210, 210, 210);
}

.player-final-results-screen.third {
  color: rgb(190, 178.8, 57.6);
}

.player-final-results-screen .new-game-button {
  font-size: 1rem;
  margin-top: 20px;
  border: none;
  background: none;
  color: rgb(167, 235, 250);
  -webkit-text-decoration: underline double;
          text-decoration: underline double;
}

.player-final-results-screen .new-game-button:hover, .player-final-results-screen .new-game-button:focus {
  cursor: pointer;
  color: rgb(197, 255, 255);
}
.teacher-profile-main {
  min-height: calc(100vh - 120px);
  background-color: rgb(233, 233, 255);
  text-align: left;
  padding: 20px;
  margin-left: -20px;
  width: 100vw;
}

.teacher-profile-main .content-container {
  max-width: 1300px;
  margin: 0 auto;
}


@media only screen and (min-width: 1000px) {
  .teacher-dashboard-games {
    display: inline-block;
    width: calc(100vw - 350px);
    max-width: 966px;
    margin-left: 50px;
  }
}
.game-list {
  list-style: none;
  width: 100%;
}

.game-list-item {
  border: 1px solid black;
  margin: 20px 0;
  max-width: 950px;
  padding: 8px;
}

.game-list-item h3 {
  display: inline-block;
  margin: 0 20px 10px 0;
  padding: 5px;
}

.game-list-item .game-list-buttons {
  margin-bottom: 20px;
}

.game-list-item .game-list-buttons button {
  margin-right: 10px;
  padding: 5px;
  border-radius: 5px;
}

.game-list-item .game-list-buttons button:hover {
  cursor: pointer;
}

/* PLAY LIVE BUTTON */
.game-list-item .game-list-buttons button:nth-child(1) {
  background-color: rgb(110, 10, 43);
  color: white;
  font-size: 1.3rem;
}

.game-list-item .game-list-buttons button:nth-child(1):hover {
  background-color: rgb(90, 10, 53);
}

/* EDIT BUTTON */
.game-list-item .game-list-buttons button:nth-child(2) {
  background: none;
  border: none;
  text-decoration: underline;
}

/* DELETE BUTTON */
.game-list-item .game-list-buttons button:nth-child(3) {
  background: none;
  border: none;
  text-decoration: underline;
}

.game-list-item .description {
  font-style: italic;
  max-width: 100%;
  height: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media only screen and (min-width: 500px) {
  .game-list-item .game-list-buttons {
    float: right;
  }
}
.dashboard-buttons {
  width: 100%;
  max-width: 250px;
  list-style: none;
  margin: 20px auto;
  box-shadow: 4px 4px rgb(80, 150, 180);
  vertical-align: top;
}

.profile-pic {
  height: 250px;
  width: 100%;
  background-image: url(/static/media/profile-pic-placeholder.ee4a5fa0.png);
  background-position: top center;
}

@media only screen and (min-width: 1000px) {
  .dashboard-buttons {
    display: inline-block;
  }
}
.dashboard-button button {
  width: 100%;
  padding: 8px;
  background-color: none;
  border: 3px solid white;
  -webkit-transition: border-bottom 250ms linear, background-color 250ms linear;
  transition: border-bottom 250ms linear, background-color 250ms linear;
}

.dashboard-button button:hover {
  cursor: pointer;
  border-bottom: 3px solid rgb(224, 190, 176);
  background-color: rgb(240, 240, 240);
  -webkit-transition: border-bottom 250ms linear, background-color 250ms linear;
  transition: border-bottom 250ms linear, background-color 250ms linear;
}

.dashboard-button .selected {
  text-decoration: overline;
  color: rgb(134, 50, 46);
  background-color: rgb(254, 210, 196);
}
.lightbox-container {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  background-color: rgba(100, 100, 100, 0.8);
}

.create-game-lightbox {
  overflow: scroll;
  background-color: white;
  padding: 20px;
  display: inline-block;
  width: 90vw;
  height: 90vh;
  margin: 5vh 5vw;
  border: 3px solid rgb(50, 120, 130);
  border-radius: 10px;
}

.create-game-lightbox h1 {
  font-size: 1.6rem;
  margin-bottom: 30px;
}


.create-game-lightbox .create-game-error {
  margin: 0;
  color: red;
  font-style: italic;
}

@media only screen and (min-width: 800px) {
  .create-game-lightbox {
    width: 67vw;
    height: 67vh;
    margin: 16.5vh 16.5vw;
  }

  .create-game-lightbox h1 {
    font-size: 3rem;
  }
}
.create-game-lightbox label {
  display: block;
  font-size: 1.2rem;
}

.create-game-lightbox input {
  margin: 15px 0;
  padding: 5px;
  width: 100%;
  font-size: 1.1rem;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 5px;
}

.create-game-lightbox #title::-webkit-input-placeholder, .create-game-lightbox #description::-webkit-input-placeholder {
  font-style: italic;
  color: rgb(180, 180, 180);
}

.create-game-lightbox #title::-ms-input-placeholder, .create-game-lightbox #description::-ms-input-placeholder {
  font-style: italic;
  color: rgb(180, 180, 180);
}

.create-game-lightbox #title::placeholder, .create-game-lightbox #description::placeholder {
  font-style: italic;
  color: rgb(180, 180, 180);
}

.create-game-lightbox #description {
  padding: 5px;
  width: 100%;
  margin: 15px 0;
  font-size: 1.1rem;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 5px;
}


.create-game-lightbox button[type="submit"] {
  background-color: rgb(40, 150, 100);
  font-size: 1.2rem;
  width: 200px;
  height: 80px;
  padding: 20px;
  border-radius: 10px;
  color: white;
  margin: 10px calc(100% - 200px) 15px 0;
  -webkit-transition: background-color 250ms linear, color 250ms linear;
  transition: background-color 250ms linear, color 250ms linear;
}

.create-game-lightbox button[type="submit"]:hover {
  cursor: pointer;
  background-color: white;
  color: rgb(40, 150, 100);
  border: 4px double rgb(40, 150, 100);
  -webkit-transition: background-color 250ms linear, color 250ms linear;
  transition: background-color 250ms linear, color 250ms linear;
}

.create-game-lightbox button[type="reset"] {
  font-size: 1rem;
  padding: 0;
  color: rgb(180, 180, 180);
  background: none;
  text-decoration: underline;
  border: none;
}

.create-game-lightbox button[type="reset"]:hover {
  cursor: pointer;
}

@media only screen and (min-width: 800px) {
  .create-game-lightbox label {
    display: inline-block;
  }

  .create-game-lightbox #title {
    margin: 0 0 25px 20px;
    font-size: 1.3rem;
    width: calc(100% - 65px);
  }

  .create-game-lightbox #description {
    padding: 5px;
    width: calc(100% - 130px);
    margin: 0 0 0 20px;
    font-size: 1.3rem;
  }

  .create-game-lightbox label[for="description"] {
    vertical-align: top;
  }

  .create-game-lightbox button[type="submit"] {
    margin-top: 25px;
  }
}



.create-questions-main {
  background-color: rgb(220, 220, 220);
  text-align: left;
  max-width: 1000px;
  margin: 0 auto;
  padding: 10px;
}

.create-questions-main .error-message {
  color: rgb(100, 0, 0);
  font-style: italic;
}

.create-questions-main #delete-button {
  background: none;
  border: none;
  width: auto;
  width: initial;
  display: inline;
  display: initial;
  margin-left: 10px;
}

.create-questions-main #delete-button:hover, .create-questions-main #delete-button:focus {
  cursor: pointer;
  color: rgb(80, 80, 80);
}

.create-questions-main button {
  width: 166px;
  display: block;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 1.1rem;
  border-radius: 5px;
}

.create-questions-main button[type='reset'] {
  margin-right: 40px;
  background-color: black;
  color: white;
  border: 1px solid white;
  -webkit-transition: border 250ms linear, background-color 200ms linear, color 200ms linear;
  -webkit-transition: border 200ms linear, background-color 200ms linear, color 200ms linear;
  transition: border 200ms linear, background-color 200ms linear, color 200ms linear;
}

.create-questions-main button[type='reset']:hover, .create-questions-main button[type='reset']:focus {
  cursor: pointer;
  background-color: white;
  color: black;
  border: 1px solid black;
  -webkit-transition: border 200ms linear, background-color 200ms linear, color 200ms linear;
  transition: border 200ms linear, background-color 200ms linear, color 200ms linear;
}

.create-questions-main button.next-question {
  background-color: purple;
  color: white;
  border: 1px solid purple;  
  -webkit-transition: border 200ms linear, background-color 200ms linear, color 200ms linear;
  transition: border 200ms linear, background-color 200ms linear, color 200ms linear;
}

.create-questions-main button.next-question:hover, .create-questions-main button.next-question:focus {
  cursor: pointer;
  background-color: white;
  color: purple;
  border: 1px solid purple;
  -webkit-transition: border 200ms linear, background-color 200ms linear, color 200ms linear;
  transition: border 200ms linear, background-color 200ms linear, color 200ms linear;
}

.create-questions-main button.previous-question {
  background-color: rgb(197, 109, 0);
  color: white;
  border: 1px solid white;  
  -webkit-transition: border 200ms linear, background-color 200ms linear, color 200ms linear;
  transition: border 200ms linear, background-color 200ms linear, color 200ms linear;
}

.create-questions-main button.previous-question:hover, .create-questions-main button.previous-question:focus {
  cursor: pointer;
  background-color: white;
  color: rgb(197, 109, 0);
  border: 1px solid rgb(197, 109, 0);
  -webkit-transition: border 200ms linear, background-color 200ms linear, color 200ms linear;
  transition: border 200ms linear, background-color 200ms linear, color 200ms linear;
}

@media only screen and (min-width: 500px) {
  .create-questions-main button {
    display: inline;
    display: initial;
    width: auto;
    width: initial;
  }

  .create-questions-main button.previous-question {
    margin-right: 20px;
  }

  .create-questions-main .float-right-container {
    float: right;
  }
}
.new-equation-input {
  margin: 30px 0;
  padding: 5px;
}

.new-equation-input.correct {
  background-color: rgb(230, 255, 230);
}
.new-equation-input.incorrect {
  background-color: rgb(255, 230, 230);
}
.new-equation-input.couldNotVerify {
  background-color: rgb(255, 255, 230);
}

.new-equation-input legend {
  font-size: 1.2rem;
}

.new-equation-input .verify-icon .placeholder-span {
  width: 32px;
  height: 18px;
  display: inline-block;
}
.new-equation-input .verify-icon .sk-spinner {
  color: rgb(135, 135, 135);
  display: inline-block;
  height: 18px;
  margin: 0 5px;
}
.new-equation-input .verify-icon .fa-check {
  margin: 0 6.5px;
  color: rgb(20, 100, 20);
}
.new-equation-input .verify-icon .fa-times-circle {
  margin: 0 6.5px;
  color: rgb(100, 20, 20);
}
.new-equation-input .verify-icon .fa-exclamation {
  color: rgb(120, 120, 20);
  margin: 0 12px;
}

.new-equation-input label {
  display: block;
  margin-bottom: 10px;
}

.new-equation-input input {
  width: calc(100% - 40px);
  font-size: 1.1rem;
  padding: 3px;
  margin: 0 10px 10px 10px;
}

.new-equation-input input::-webkit-input-placeholder {
  color: rgb(170, 170, 170);
  font-style: italic;
}

.new-equation-input input::-ms-input-placeholder {
  color: rgb(170, 170, 170);
  font-style: italic;
}

.new-equation-input input::placeholder {
  color: rgb(170, 170, 170);
  font-style: italic;
}

.new-equation-input .create-equation-input {
  margin-right: 20px;
}

.new-equation-input .error-message {
  margin: 0 0 0 0;
}

@media only screen and (min-width: 500px) {
  .new-equation-input label {
    display: inline;
    display: initial;
    margin-left: 10px;
  }

  .new-equation-input input {
    width: calc(100% - 115px);
  }

  .new-equation-input .create-answer-input {
    margin: 10px 10px 10px 22px;
  }

  .new-equation-input .error-message {
    margin: 0 0 0 10px;
  }
}

@media only screen and (min-width: 1000px) {
  .new-equation-input input {
    font-size: 1.3rem;
  }
  .new-equation-input .create-equation-input {
    width: 60%;
  }
  .new-equation-input .create-answer-input {
    width: 15%;
  }
}
.question-navigator-list {
  list-style: none;
  margin-bottom: 20px;
}

.question-navigator-item {
  display: inline-block;
  border: 1px solid green;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 8px 20px 0 20px;
}

.question-navigator-item button {
  font-size: 1.1rem;
  border-radius: 50%;
  background-color: transparent;
  border: none;
  color: purple;
  width: 100%;
  height: 100%;
}

.question-navigator-item button:hover, .question-navigator-item button:focus {
  cursor: pointer;
  background-color: rgb(200, 255, 200);
}

.question-navigator-item.current {
  background-color: rgb(230, 255, 200);
}
.delete-question-lightbox {
  width: calc(100vw - 20px);
  max-width: 600px;
  margin: 16.5vh auto;
  height: calc(66vh);
  max-height: 600px;
  background-color: white;
  border-radius: 10px;
  border: solid 2px rgb(50, 120, 130);
  padding: 20px;
}

.delete-question-lightbox label {
  font-size: 1.5rem;
  display: block;
  margin-bottom: 30px;
  margin-top: calc(22vh - 85px);
}

.delete-question-lightbox button {
  width: 100%;
  max-width: 350px;
  max-height: 100px;
  height: 14vh;
  display: block;
  margin: 20px auto;
  -webkit-transition: border-bottom 250ms linear, background-color 250ms linear;
  transition: border-bottom 250ms linear, background-color 250ms linear;
}

.delete-question-lightbox button[type='submit'] {
  color: white;
  background-color: rgb(50, 120, 130);
}
.delete-question-lightbox button[type='submit']:hover, .delete-question-lightbox button[type='submit']:focus {
  cursor: pointer;
  background-color: white;
  color: rgb(50, 120, 130);
  border: 1px solid rgb(50, 120, 130);
}

.delete-question-lightbox button[type='reset'] {
  color: white;
  background-color: black;
  margin: 20px auto;
}
.create-new-button {

}

.create-new-button button {
  border-radius: 50px;
  border: none;
  background-color: rgb(233, 233, 255);
  color: rgb(40, 150, 100);
  font-size: 5rem;
}

.create-new-button i:hover, .create-new-button p:hover {
  cursor: pointer;
}

.create-new-button p {
  color: white;
  background-color: rgb(40, 150, 100);
  display: inline;
  font-size: 1.5rem;
  border-radius: 5%;
  padding: 10px 10px 10px 5px;
  position: relative;
  bottom: 23px;
  right: 14px;
}

@media only screen and (min-width: 1000px) {
  .create-new-button {
    text-align: right;
  }
}

.delete-game-lightbox {
  text-align: center;
  background-color: white;
  padding: 20px;
  display: inline-block;
  width: 67vw;
  max-height: 67vh;
  margin: calc(16.5vh - 20px) 16.5vw 16.5vh 16.5vw;
  border: 3px solid rgb(120, 50, 50);
  border-radius: 10px;
}

.delete-game-lightbox .title-name {
  font-style: italic;
}

.delete-game-lightbox p {
  font-size: 1.5rem;
  margin-bottom: 40px;
}

.delete-game-lightbox button {
  width: 100px;
  height: 80px;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
  font-size: 1.2rem;
  display: block;
  margin: 40px auto;
}

.delete-game-lightbox .confirm-delete {
  background-color: rgb(120, 50, 50);
  -webkit-transition: color 250ms linear, background-color 250ms linear;
  transition: color 250ms linear, background-color 250ms linear;
}

.delete-game-lightbox .confirm-delete:hover, .delete-game-lightbox .confirm-delete:focus {
  cursor: pointer;
  background-color: white;
  color: rgb(120, 50, 50);
  border: 1px solid rgb(120, 50, 50);
  -webkit-transition: color 250ms linear, background-color 250ms linear;
  transition: color 250ms linear, background-color 250ms linear;
}

.delete-game-lightbox .cancel-delete {
  background-color: purple;
  -webkit-transition: color 250ms linear, background-color 250ms linear;
  transition: color 250ms linear, background-color 250ms linear;
}

.delete-game-lightbox .cancel-delete:hover, .delete-game-lightbox .cancel-delete:focus {
  cursor: pointer;
  background-color: white;
  color: purple;
  border: 1px solid purple;
  -webkit-transition: color 250ms linear, background-color 250ms linear;
  transition: color 250ms linear, background-color 250ms linear;
}

@media only screen and (min-width: 480px) {
  .delete-game-lightbox button {
    display: inline-block;
  }

  .delete-game-lightbox .confirm-delete {
    margin: 0 40px 0 0;
  }
  
  .delete-game-lightbox .cancel-delete {
    margin: 0 0 0 40px;
  }
}
.dashboard-user-message {
  width: calc(100vw);
  font-style: italic;
  background-color: rgb(80, 60, 60);
  padding: 0;
  color: rgb(70, 0, 0);
  position: fixed;
  top: 0;
  left: 0;

  overflow: hidden;
  max-height: 0px;
  padding: 0px;
  -webkit-transition: max-height 0.5s ease-out;
  transition: max-height 0.5s ease-out;
}

.dashboard-user-message.expanded {
  max-height: 100px;
  -webkit-transition: max-height 0.5s ease-out;
  transition: max-height 0.5s ease-out;
}

.dashboard-user-message .message-container {
  padding: 15px;
}

.dashboard-user-message button {
  background: none;
  border: none;
  font-size: 18px;
  position: absolute;
  top: 12px;
  left: 16px;
}

.dashboard-user-message button:hover, .dashboard-user-message button:focus {
  cursor: pointer;
  text-shadow: 0px 0px 2px rgb(80, 60, 60);
}

.dashboard-user-message p {
  padding: 10px 10px 10px 18px;
  border: 1px dashed white;
  background-color: rgb(220, 220, 220);
}
.teacher-gameplay-waiting {
  color: white;
  margin-top: 30px;
}

.teacher-gameplay-waiting h2 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.teacher-gameplay-waiting .go-to-url {
  margin-bottom: 10px;
}
.teacher-gameplay-waiting .go-to-url span {
  font-size: 1.5rem;
  color: rgb(255, 255, 140);
}

.teacher-gameplay-waiting .player-list {
  list-style: none;
  text-align: left;
}

.teacher-gameplay-waiting .player-list .player-name {
  font-size: 1.3rem;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 10px 0;
}
.teacher-gameplay-waiting .player-list .player-name .player-name-text {
  display: inline;
}
.teacher-gameplay-waiting .player-list .player-name .player-name-text:hover {
  cursor: not-allowed;
  text-decoration: line-through;
  font-style: italic;
}

.teacher-gameplay-waiting .start-button {
  padding: 5px;
  border-radius: 5px;
  margin: 20px 20px 0 0;
  font-size: 1.5rem;
  background-color: rgb(85, 170, 88);
  color: rgb(240, 240, 240);
}

.teacher-gameplay-waiting .start-button:hover {
  cursor: pointer;
  background-color: rgb(65, 150, 68);
  color: rgb(240, 240, 240);
}

.teacher-gameplay-waiting .quit-button {
  padding: 5px;
  border-radius: 5px;
  font-size: 1.1rem;
}

.teacher-gameplay-waiting .quit-button:hover, .teacher-gameplay-waiting .quit-button:focus {
  cursor: pointer;
  background-color: rgb(230, 230, 230);
}

@media only screen and (min-width: 500px) {
  .teacher-gameplay-waiting .player-list .player-name {
    width: 50%;
    margin: 20px 0;
  }
}

@media only screen and (min-width: 700px) {
  .teacher-gameplay-waiting .player-list .player-name {
    width: 33%;
  }
}

@media only screen and (min-width: 1000px) {
  .teacher-gameplay-waiting .player-list .player-name {
    width: 25%;
  }
}
.live-game-ready-screen {
  max-width: 1100px;
  margin: 0 auto;
}

.live-game-ready-screen p {
  text-align: center;
}

.live-game-ready-screen h2 {
  font-size: 2.6rem;
  margin-bottom: 10px;
}

.live-game-ready-screen .start-button {
  font-size: 1.2rem;
  padding: 5px;
  background-color: rgb(85, 170, 88);
  color: rgb(240, 240, 240);
  border-radius: 5px;
  margin-right: 40px;
}

.live-game-ready-screen .start-button:hover, .live-game-ready-screen .start-button:focus {
  cursor: pointer;
  background-color: rgb(65, 150, 68);
  color: rgb(240, 240, 240);
}

.live-game-ready-screen .shuffle-button {
  padding: 5px;
  background-color: rgb(60, 100, 150);
  color: white;
  font-size: 1rem;
  border-radius: 5px;
}

.live-game-ready-screen .shuffle-button:hover, .live-game-ready-screen .shuffle-button:focus {
  cursor: pointer;
  background-color: rgb(40, 80, 130);
  color: rgb(240, 240, 240);
}

.live-game-ready-screen .team-list {
  list-style: none;
  text-align: left;
}

.live-game-ready-screen .team {
  display: inline-block;
  width: 100%;
  margin: 20px 0;
  vertical-align: top;
}

.live-game-ready-screen .player-list {
  list-style: none;
}

.live-game-ready-screen .player-name {
  padding-left: 5px;
}

@media only screen and (min-width: 500px) {
  .live-game-ready-screen .team {
    width: 50%;
  }
}

@media only screen and (min-width: 700px) {
  .live-game-ready-screen .team {
    width: 33%;
  }
}

@media only screen and (min-width: 1000px) {
  .live-game-ready-screen .team {
    width: 25%;
  }
}
.live-game-scoreboard {
  color: white;
  max-width: 1100px;
  margin: 30px auto 0 auto;
}

.live-game-scoreboard .team-score-list {
  list-style: none;
  border-top: 1px solid gray;
}

.live-game-scoreboard h2 {
  display: inline-block;
  font-size: 2rem;
}

.live-game-scoreboard button {
  border: none;
  background: none;
  text-decoration: underline;
  color: rgb(220, 200, 255);
  margin-left: 20px;
}

.live-game-scoreboard button:hover, .live-game-scoreboard button:focus {
  cursor: pointer;
  color: rgb(255, 220, 220);
}

@media only screen and (min-width: 500px) {
  .live-game-scoreboard h2 {
    font-size: 3rem;
  }
}
.team-score-item {
  text-align: left;
  margin-bottom: 20px;
}

.team-score-item .team-place-number {
  font-size: 3.5rem;
  float: left;
  margin-right: 20px;
  width: 20px;
}

.team-score-item h3 {
  font-size: 1.5rem;
  display: inline-block;
  width: calc(100% - 45px);
  height: 2rem;
  overflow: hidden;
  text-overflow: clip;
}

.team-score-item .team-points {
  display: inline-block;
}

@media only screen and (min-width: 500px) {
  .team-score-item h3 {
    font-size: 2rem;
    height: 2.7rem;
  }
}

@media only screen and (min-width: 600px) {
  .team-score-item .team-points,
  .team-score-item .team-questions-answered {
    display: inline-block;
  }

  .team-score-item h3 {
    width: calc(100% - 230px);
    padding-right: 40px;
  }

  .team-score-item .team-points {
    font-size: 2rem;
  }

  .team-score-item .team-questions-answered {
    position: relative;
    bottom: 10px;
  }
}

@media only screen and (min-width: 800px) {
  .team-score-item h3,
  .team-score-item .team-points,
  .team-score-item .team-questions-answered {
    display: inline-block;
  }

  .team-score-item h3 {
    width: calc(100% - 320px);
    font-size: 3rem;
    height: 4rem;
    padding-right: 50px;
  }

  .team-score-item .team-points {
    font-size: 3rem;
  }
}
.final-results-screen {
  color: white;
  max-width: 1200px;
  margin: 30px auto 0 auto;
}

.final-results-screen h2 {
  display: inline-block;
}

.final-results-screen button {
  background: none;
  border: none;
  text-decoration: underline;
  color: rgb(220, 220, 255);
  margin-left: 20px;
}

.final-results-screen .restart-button:hover, .final-results-screen .restart-button:focus {
  cursor: pointer;
  color: rgb(240, 240, 255);
}

.final-results-screen .podium-team-list {
  list-style: none;
  border-top: 1px solid gray;
  margin-top: 20px;
  text-align: left;
  padding-top: 20px;
}

.final-results-screen .podium-team {
  margin-bottom: 20px;
}


.final-results-screen li:nth-child(1) {
  color: rgb(255, 215, 0);
}
.final-results-screen li:nth-child(1) .team-place {
  font-size: 3rem;
}
.final-results-screen li:nth-child(1) .team-name {
  font-size: 2.8rem;
  max-height: 3.8rem;
}

.final-results-screen li:nth-child(2) {
 color: rgb(210, 210, 210);
}
.final-results-screen li:nth-child(2) .team-place {
  font-size: 2.7rem;
}
.final-results-screen li:nth-child(2) .team-name {
  font-size: 2.5rem;
  max-height: 3.5rem;
}

.final-results-screen li:nth-child(3) {
  color: rgb(190, 178.8, 57.6);
}
.final-results-screen li:nth-child(3) .team-place {
  font-size: 2.5rem;
}
.final-results-screen li:nth-child(3) .team-name {
  font-size: 2.3rem;
  max-height: 3.3rem;
}

.final-results-screen .team-place {
  float: left;
  width: 40px;
}

.final-results-screen .team-name {
  overflow: hidden;
  text-overflow: clip;
}

@media only screen and (min-width: 600px) {
  .final-results-screen .team-name {
    display: inline-block;
    width: calc(100% - 285px);
    padding-right: 20px;
  }
  .final-results-screen .team-points {
    display: inline-block;
    font-size: 2.5rem;
  }
}
