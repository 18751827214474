html {
  font-size: 16px;
}

body {
  min-height: 100vh;
  min-width: 100vw;
  background-color: rgb(50, 120, 130);
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  margin: 0;
}

body * {
  font-family: 'Quicksand', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

.error-message {
  color: rgb(255, 200, 200);
  margin: 10px 0;
}

.error-input {
  background-color: rgb(255, 230, 230);
}

