.live-game-ready-screen {
  max-width: 1100px;
  margin: 0 auto;
}

.live-game-ready-screen p {
  text-align: center;
}

.live-game-ready-screen h2 {
  font-size: 2.6rem;
  margin-bottom: 10px;
}

.live-game-ready-screen .start-button {
  font-size: 1.2rem;
  padding: 5px;
  background-color: rgb(85, 170, 88);
  color: rgb(240, 240, 240);
  border-radius: 5px;
  margin-right: 40px;
}

.live-game-ready-screen .start-button:hover, .live-game-ready-screen .start-button:focus {
  cursor: pointer;
  background-color: rgb(65, 150, 68);
  color: rgb(240, 240, 240);
}

.live-game-ready-screen .shuffle-button {
  padding: 5px;
  background-color: rgb(60, 100, 150);
  color: white;
  font-size: 1rem;
  border-radius: 5px;
}

.live-game-ready-screen .shuffle-button:hover, .live-game-ready-screen .shuffle-button:focus {
  cursor: pointer;
  background-color: rgb(40, 80, 130);
  color: rgb(240, 240, 240);
}

.live-game-ready-screen .team-list {
  list-style: none;
  text-align: left;
}

.live-game-ready-screen .team {
  display: inline-block;
  width: 100%;
  margin: 20px 0;
  vertical-align: top;
}

.live-game-ready-screen .player-list {
  list-style: none;
}

.live-game-ready-screen .player-name {
  padding-left: 5px;
}

@media only screen and (min-width: 500px) {
  .live-game-ready-screen .team {
    width: 50%;
  }
}

@media only screen and (min-width: 700px) {
  .live-game-ready-screen .team {
    width: 33%;
  }
}

@media only screen and (min-width: 1000px) {
  .live-game-ready-screen .team {
    width: 25%;
  }
}