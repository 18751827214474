.index-landing-page .loading-spinner {
  text-align: center;
}

.index-landing-page {
  text-align: center;
}

.index-landing-page #sparkles-container {
  position: absolute;
  top: 175px;
  left: 0;
  z-index: -1;
  width: calc(100% + 60px);
  height: calc(100vh - 175px);
  margin: -30px 0 0 -40px;
}

.index-landing-page canvas {
  transition: visibility 0ms, opacity 1500ms linear;
  -webkit-transition: visibility 0ms, opacity 1500ms linear;
  -ms-transition: visibility 0ms, opacity 1500ms linear;
}