.teacher-main {
  margin-top: 30px;
  text-align: center;
  color: white;
}

.teacher-main h2 {
  margin-bottom: 30px;
}

.teacher-main label {
  margin-bottom: 10px;
  display: block;
  text-align: center;
}

.teacher-main input {
  margin-bottom: 20px;
  text-align: center;
  width: 250px;
  padding: 5px;
  font-size: 1.2rem;
}

.teacher-main button {
  display: block;
  margin: 0 auto 20px auto;
  text-align: center;
}

.teacher-main .toggle-login-register button {
  display: initial;
  margin: 0 0 0 8px;
}

.teacher-main .loading-spinner {
  margin: 20px auto 0 auto;
  width: 5px;
  height: 50px;
}

.teacher-main button[type='submit'] {
  color: rgb(30, 100, 110);
  font-size: 1.1rem;
  margin-bottom: 40px;
  padding: 5px;
  border-radius: 5px;
  -webkit-transition: background-color 250ms linear, color 250ms linear;
  -ms-transition: background-color 250ms linear, color 250ms linear;
  transition: background-color 250ms linear, color 250ms linear;
}

.teacher-main button[type='submit']:hover, .teacher-main button[type='submit']:focus {
  cursor: pointer;
  color: white;
  background-color: rgb(40, 110, 120);
  -webkit-transition: background-color 250ms linear, color 250ms linear;
  -ms-transition: background-color 250ms linear, color 250ms linear;
  transition: background-color 250ms linear, color 250ms linear;
}

.teacher-main button.demo-account-button, 
.teacher-main button.toggle-login-register-button {
  background: none;
  border: none;
  text-decoration: underline;
  color: rgb(230, 230, 230);
}

.teacher-main button.demo-account-button:hover, 
.teacher-main button.demo-account-button:focus, 
.teacher-main button.toggle-login-register-button:hover,
.teacher-main button.toggle-login-register-button:focus {
  cursor: pointer;
  color: white;
}




/* ------- Animation for Loading Paragraph ----------- */
.teacher-main .spinner-text {
  color: white;
  margin-top: 20px;

  -webkit-animation: fadein 1.5s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 1.5s; /* Firefox < 16 */
      -ms-animation: fadein 1.5s; /* Internet Explorer */
       -o-animation: fadein 1.5s; /* Opera < 12.1 */
          animation: fadein 1.5s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}