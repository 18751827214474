.player-final-results-screen {
  color: white;
  margin-top: 60px;
}

.player-final-results-screen .team-score {
  font-size: 2.5rem;
}

.player-final-results-screen .team-place {
  font-size: 2.5rem;
}

.player-final-results-screen.first {
  color: rgb(255, 215, 0);
}

.player-final-results-screen.second {
  color: rgb(210, 210, 210);
}

.player-final-results-screen.third {
  color: rgb(190, 178.8, 57.6);
}

.player-final-results-screen .new-game-button {
  font-size: 1rem;
  margin-top: 20px;
  border: none;
  background: none;
  color: rgb(167, 235, 250);
  text-decoration: underline double;
}

.player-final-results-screen .new-game-button:hover, .player-final-results-screen .new-game-button:focus {
  cursor: pointer;
  color: rgb(197, 255, 255);
}