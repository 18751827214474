.lightbox-container {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  background-color: rgba(100, 100, 100, 0.8);
}

.create-game-lightbox {
  overflow: scroll;
  background-color: white;
  padding: 20px;
  display: inline-block;
  width: 90vw;
  height: 90vh;
  margin: 5vh 5vw;
  border: 3px solid rgb(50, 120, 130);
  border-radius: 10px;
}

.create-game-lightbox h1 {
  font-size: 1.6rem;
  margin-bottom: 30px;
}


.create-game-lightbox .create-game-error {
  margin: 0;
  color: red;
  font-style: italic;
}

@media only screen and (min-width: 800px) {
  .create-game-lightbox {
    width: 67vw;
    height: 67vh;
    margin: 16.5vh 16.5vw;
  }

  .create-game-lightbox h1 {
    font-size: 3rem;
  }
}