.teacher-profile-main {
  min-height: calc(100vh - 120px);
  background-color: rgb(233, 233, 255);
  text-align: left;
  padding: 20px;
  margin-left: -20px;
  width: 100vw;
}

.teacher-profile-main .content-container {
  max-width: 1300px;
  margin: 0 auto;
}


@media only screen and (min-width: 1000px) {
  .teacher-dashboard-games {
    display: inline-block;
    width: calc(100vw - 350px);
    max-width: 966px;
    margin-left: 50px;
  }
}