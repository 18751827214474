.landing-page-header h1 {
  font-family: 'Indie Flower', cursive;
  font-size: 1.2rem;
}

.landing-page-header h1 span:nth-child(1) {
  background-color: rgb(234, 150, 146);
  color: rgb(50, 120, 130);
  font-style: italic;
  padding: 8px 5px 8px 8px;
  text-decoration: underline;
  border: 1px solid rgb(234, 150, 146);
}

.landing-page-header h1 span:nth-child(2) {
  background-color: rgb(50, 120, 130);
  color: rgb(234, 150, 146);
  font-style: italic;
  padding: 8px 8px 8px 0;
  border: 1px solid rgb(234, 150, 146);
  text-decoration: underline;
}


.landing-page-header ul {
  width: 100vw;
  margin: 15px 0 0 -20px;
  background-color: rgb(40, 110, 120);
}


.landing-page-header li {
  display: inline-block;
  width: 33%;
}

.landing-page-header li a {
  display: inline-block;
  padding: 8px;
  width: 100%;
  color: white;
  border-bottom: 1px solid rgb(40, 110, 120);
  -webkit-transition: border-bottom 250ms linear, background-color 250ms linear;
  -ms-transition: border-bottom 250ms linear, background-color 250ms linear;
  transition: border-bottom 250ms linear, background-color 250ms linear;
}

.landing-page-header .selected-route {
  color: rgb(234, 150, 146);
  text-decoration: overline;
  font-weight: bold;
}

.landing-page-header li a:hover, .landing-page-header li a:focus {
  cursor: pointer;
  background-color: rgb(50, 120, 130);
  border-bottom: 1px solid white;
  -webkit-transition: border-bottom 250ms linear, background-color 250ms linear;
  -ms-transition: border-bottom 250ms linear, background-color 250ms linear;
  transition: border-bottom 250ms linear, background-color 250ms linear;
}

/* ----- Styling for slide-up and slide-down of header nav-list ----- */
.landing-page-header .nav-list li.slideup, 
.landing-page-header .nav-list li,
.landing-page-header .nav-list.slideup,
.landing-page-header .nav-list {
  max-height: 0px;
  overflow-y: hidden;
  -webkit-transition: max-height 1.5s ease-in-out;
  -moz-transition: max-height 1.5s ease-in-out;
  -o-transition: max-height 1.5s ease-in-out;
  transition: max-height 1.5s ease-in-out;
}
.landing-page-header .nav-list li,
.landing-page-header .nav-list {
  overflow-y: initial;
  max-height: 100px;
}



@media only screen and (min-width: 600px) {
  .landing-page-header h1 {
    font-size: 2rem;
  }
  .landing-page-header ul {
    text-align: right;
  }
  .landing-page-header li {
    width: initial;
  }
}

@media only screen and (min-width: 1000px) {
  .landing-page-header ul {
    padding-right: calc((100vw - 1000px) / 2);
  }
}